<template>
  <div id="frame">
  <div id="sidepanel">
    <div id="search">
      <img class="find" src="../assets/img/icon/iconmonstr-magnifier-2.svg" alt="">
      <input
        @keydown="applySearch()" v-model="searchInput"
        type="text" placeholder="Search contacts..." />
    </div>
    <div id="contacts">
      <ul>
        <div v-for="(c, index) of filteredContacts" :key="index">
          <div @click="activateRoom(c);" v-show="c.visible">
            <li class="contact" :class="{ 'active': c.active }">
              <span class="text-danger notification">{{ c.unread === 0 ? '' : c.unread }}</span>
            <div class="wrap">
              <div class="contact-logo">{{ c.user_info[1] || 'U'}}</div>
              <div class="meta">
                <p class="name">{{ c.user_info }}</p>
                <p class="preview">{{ c.message }}</p>
              </div>
            </div>
          </li>
          </div>
        </div>
      </ul>
    </div>
  </div>
  <div class="content">
    <div v-for="(c, index) of onlineUsers" :key="index">
      <div v-if="c.active">
        <ChatInstance :room="c.room" :userConnect="c.user_id || '0'"
          :chatHeader="c.user_info" @unreadChanged="readChanged"/>
      </div>
    </div>
  </div>
</div>
</template>
<script>
const ChatInstance = () => import('./ChatInstance.vue');

export default {
  name: 'ChatAdmin',
  data() {
    return {
      onlineUsers: [],
      timeInterval: null,
      searchInput: '',
    };
  },
  mounted() {
    this.getOnlineUsers();
    this.timeInterval = setInterval(this.getOnlineUsers, 10000);
    this.$nextTick(() => {
      // this.getContacts();
    });
  },
  computed: {
    filteredContacts() {
      let result = [];
      if (this.searchInput && this.searchInput !== '') {
        result = this.onlineUsers.find((el) => el.user_info === this.searchInput);
        // eslint-disable-next-line max-len
        result = this.onlineUsers.filter((el) => el.user_info.toLowerCase().includes(this.searchInput.toLowerCase()));
      } else {
        result = this.onlineUsers;
      }
      return result;
    },
  },
  components: {
    ChatInstance,
  },
  methods: {
    applySearch() {
      this.$nextTick(() => {});
    },
    readChanged(value) {
      const updatedRead = this.onlineUsers.find((el) => el.room === value.room);
      updatedRead.unread = value.unread;
    },
    activateRoom(chatInstance) {
      Array.from(this.onlineUsers).forEach((e) => {
        e.active = e === chatInstance;
      });
    },
    getOnlineUsers() {
      if (this.$router.currentRoute.name !== 'ChatAdmin') {
        clearInterval(this.timeInterval);
        return;
      }
      this.$store.dispatch('chat/getOnlineUsers')
        .then((response) => {
          Array.from(response).forEach((e) => {
            const newContact = {
              visible: true,
              last_seen: e.last_seen,
              unread: e.unread,
              active: false,
              room: e.room,
              user_info: e.user_info,
              user_id: e.user_id,
            };
            // eslint-disable-next-line max-len
            if (!this.onlineUsers.some((el) => el.room === newContact.room && el.user_info === e.user_info)) {
              this.onlineUsers.push(newContact);
            } else {
              // eslint-disable-next-line max-len
              const currentElement = this.onlineUsers.find((el) => el.room === newContact.room && el.user_info === e.user_info);
              currentElement.unread = e.unread;
            }
          });
        }).catch(() => {});
    },
    getContacts() {
      this.$store.dispatch('chat/getContacts')
        .then(() => {});
    },
  },
};
</script>

<style scoped>
  .find {
    height: 15px;
    margin: 15px 0 0 20px;
    position: absolute;
  }

  .name {
    margin-bottom: 0;
    text-transform: capitalize;
  }

  .preview {
    font-style: italic;
  }
  #message-container {
    background: #ebe6df;
  }
  .cp-pen-styles>body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #27ae60;
  font-size: 1em;
  letter-spacing: 0.1px;
  color: #272627;
  text-rendering: optimizeLegibility;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased;
}
.wrap {
  min-width: 220px !important;
}
#frame {
  min-width: 360px;
  max-width: 1000px;
  height: 82vh;
  background: #E6EAEA;
}

@media screen and (max-width: 991px) and (min-width: 800px) {
  #frame {
    margin-left: -10%;
    margin-right: -10%;
  }
}
@media screen and (max-width: 800px) and (min-width: 576px) {
  #frame {
    margin-left: -5%;
    margin-right: -5%;
  }
}
@media screen and (max-width: 360px) {
  #frame {
    width: 100%;
    height: 100vh;
  }
}
#frame #sidepanel {
  float: left;
  min-width: 280px;
  max-width: 340px;
  width: 40%;
  height: 100%;
  background: #4b4746;
  color: #f5f5f5;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  #frame #sidepanel {
    min-width: 200px;
    max-width: 300px;
  }
}
@media screen and (max-width: 735px) {
  #frame #sidepanel {
    width: 58px;
    min-width: 58px;
  }
}
#frame #sidepanel #profile {
  width: 80%;
  margin: 25px auto;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile {
    width: 100%;
    margin: 0 auto;
    padding: 5px 0 0 0;
    background: #272627;
  }
}
#frame #sidepanel #profile.expanded .wrap {
  height: 210px;
  line-height: initial;
}
#frame #sidepanel #profile.expanded .wrap p {
  margin-top: 20px;
}
#frame #sidepanel #profile.expanded .wrap i.expand-button {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
#frame #sidepanel #profile .wrap {
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  -moz-transition: 0.3s height ease;
  -o-transition: 0.3s height ease;
  -webkit-transition: 0.3s height ease;
  transition: 0.3s height ease;
}

#frame #sidepanel #profile .wrap img {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid #e74c3c;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
}

#frame #sidepanel #profile .wrap img.online {
  border: 2px solid #2ecc71;
}
#frame #sidepanel #profile .wrap img.away {
  border: 2px solid #f1c40f;
}
#frame #sidepanel #profile .wrap img.busy {
  border: 2px solid #e74c3c;
}
#frame #sidepanel #profile .wrap img.offline {
  border: 2px solid #95a5a6;
}
#frame #sidepanel #profile .wrap p {
  float: left;
  margin-left: 15px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap p {
    display: none;
  }
}
#frame #sidepanel #profile .wrap i.expand-button {
  float: right;
  cursor: pointer;
  color: #7c766f;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap i.expand-button {
    display: none;
  }
}
#frame #sidepanel #profile .wrap #status-options {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 150px;
  margin: 70px 0 0 0;
  border-radius: 6px;
  z-index: 99;
  line-height: initial;
  background: #7c766f;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options {
    width: 58px;
    margin-top: 57px;
  }
}
#frame #sidepanel #profile .wrap #status-options.active {
  opacity: 1;
  visibility: visible;
  margin: 75px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options.active {
    margin-top: 62px;
  }
}
#frame #sidepanel #profile .wrap #status-options:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #7c766f;
  margin: -8px 0 0 24px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options:before {
    margin-left: 23px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul {
  overflow: hidden;
  border-radius: 6px;
}
#frame #sidepanel #profile .wrap #status-options ul li {
  padding: 15px 0 30px 18px;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 35px 22px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li:hover {
  background: #faf9f7;
}
#frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
    width: 14px;
    height: 14px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  margin: -3px 0 0 -3px;
  background: transparent;
  border-radius: 50%;
  z-index: 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
    height: 18px;
    width: 18px;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li p {
  padding-left: 12px;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #profile .wrap #status-options ul li p {
    display: none;
  }
}
#frame #sidepanel #profile .wrap #status-options ul li#status-online span.status-circle {
  background: #2ecc71;
}
#frame #sidepanel #profile .wrap #status-options
  ul li#status-online.active span.status-circle:before {
  border: 1px solid #2ecc71;
}
#frame #sidepanel #profile .wrap #status-options ul li#status-away span.status-circle {
  background: #f1c40f;
}
#frame #sidepanel #profile .wrap #status-options ul
  li#status-away.active span.status-circle:before {
  border: 1px solid #f1c40f;
}
#frame #sidepanel #profile .wrap #status-options ul
    li#status-busy span.status-circle {
  background: #e74c3c;
}
#frame #sidepanel #profile .wrap #status-options ul
    li#status-busy.active span.status-circle:before {
  border: 1px solid #e74c3c;
}
#frame #sidepanel #profile .wrap #status-options ul
    li#status-offline span.status-circle {
  background: #95a5a6;
}
#frame #sidepanel #profile .wrap #status-options ul
    li#status-offline.active span.status-circle:before {
  border: 1px solid #95a5a6;
}
#frame #sidepanel #profile .wrap #expanded {
  padding: 100px 0 0 0;
  display: block;
  line-height: initial !important;
}
#frame #sidepanel #profile .wrap #expanded label {
  float: left;
  clear: both;
  margin: 0 8px 5px 0;
  padding: 5px 0;
}
#frame #sidepanel #profile .wrap #expanded input {
  border: none;
  margin-bottom: 6px;
  background: #272627;
  border-radius: 3px;
  color: #f5f5f5;
  padding: 7px;
  width: 100%;
}
#frame #sidepanel #profile .wrap #expanded input:focus {
  outline: none;
  background: #7c766f;
}
#frame #sidepanel #search {
  border-top: 1px solid #272627;
  border-bottom: 1px solid #272627;
  font-weight: 300;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #search {
    display: none;
  }
}
#frame #sidepanel #search label {
  position: absolute;
  margin: 10px 0 0 20px;
}
#frame #sidepanel #search input {
  padding: 10px 0 10px 46px;
  width: 100%;
  border: none;
  background: #272627;
  color: #f5f5f5;
}
#frame #sidepanel #search input:focus {
  outline: none;
  background: #7c766f;
}
#frame #sidepanel #search input::-webkit-input-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #search input::-moz-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #search input:-ms-input-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #search input:-moz-placeholder {
  color: #f5f5f5;
}
#frame #sidepanel #contacts {
  height: calc(100% - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts {
    height: calc(100% - 149px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  #frame #sidepanel #contacts::-webkit-scrollbar {
    display: none;
  }
}
#frame #sidepanel #contacts.expanded {
  height: calc(100% - 334px);
}
#frame #sidepanel #contacts::-webkit-scrollbar {
  width: 8px;
  background: #4b4746;
}
#frame #sidepanel #contacts::-webkit-scrollbar-thumb {
  background-color: #272627;
}
#frame #sidepanel #contacts ul li.contact {
  position: relative;
  padding: 10px 0 15px 0;
  font-size: 0.9em;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact {
    padding: 6px 0 46px 8px;
  }
}
#frame #sidepanel #contacts ul li.contact:hover {
  background: #9a7338;
}
#frame #sidepanel #contacts ul li.contact.active {
  background: #272627;
  border-right: 5px solid #c5964c;
}
#frame #sidepanel #contacts ul li.contact.active span.contact-status {
  border: 2px solid #9a7338 !important;
}
#frame #sidepanel #contacts ul li.contact .wrap {
  width: 88%;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap {
    width: 100%;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap span {
  position: absolute;
  left: 0;
  margin: -2px 0 0 -2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #4b4746;
  background: #faf9f7;
}
#frame #sidepanel #contacts ul li.contact .wrap span.online {
  background: #2ecc71;
}
#frame #sidepanel #contacts ul li.contact .wrap span.away {
  background: #f1c40f;
}
#frame #sidepanel #contacts ul li.contact .wrap span.busy {
  background: #e74c3c;
}
#frame #sidepanel #contacts ul li.contact .wrap .contact-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
  background-color: #9a7338;
  color: #fff;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode',
    'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: bolder;
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap .contact-logo {
    margin-right: 0px;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap .meta {
  padding: 5px 0 0 0;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #contacts ul li.contact .wrap .meta {
    display: none;
  }
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .name {
  font-weight: 600;
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
  margin: 5px 0 0 0;
  padding: 0 0 1px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  -webkit-transition: 1s all ease;
  transition: 1s all ease;
}
#frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
  position: initial;
  border-radius: initial;
  background: none;
  border: none;
  padding: 0 2px 0 0;
  margin: 0 0 0 1px;
  opacity: .5;
}
#frame #sidepanel #bottom-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
}
#frame #sidepanel #bottom-bar button {
  float: left;
  border: none;
  width: 50%;
  padding: 10px 0;
  background: #272627;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 0.85em;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button {
    float: none;
    width: 100%;
    padding: 15px 0;
  }
}
#frame #sidepanel #bottom-bar button:focus {
  outline: none;
}
#frame #sidepanel #bottom-bar button:nth-child(1) {
  border-right: 1px solid #4b4746;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid #4b4746;
  }
}
#frame #sidepanel #bottom-bar button:hover {
  background: #7c766f;
}
#frame #sidepanel #bottom-bar button i {
  margin-right: 3px;
  font-size: 1em;
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button i {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 735px) {
  #frame #sidepanel #bottom-bar button span {
    display: none;
  }
}
#frame .content {
  float: right;
  width: calc(100% - 340px);
  height: 100%;
  overflow: hidden;
  position: relative;
  border: 1px solid #c4c0c0;
}
@media screen and (max-width: 991px) {
  #frame .content {
    width: 60%;
  }
}
@media screen and (max-width: 735px) {
  #frame .content {
    width: calc(100% - 58px);
    min-width: 300px !important;
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 680px) {
  #frame .content {
    width: calc(100% - 60px);
  }
}
#frame .content .contact-profile {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #c5964f;
  font-weight: bold;
  color: #272627;
  text-align: center;
}
#frame .content .contact-profile img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin: 9px 12px 0 9px;
}
#frame .content .contact-profile p {
  float: left;
  width: 100%;
  text-align: center;
}
#frame .content .contact-profile .social-media {
  float: right;
}
#frame .content .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}
#frame .content .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}
#frame .content .contact-profile .social-media i:hover {
  color: #7c766f;
}
#frame .content .messages {
  height: auto;
  min-height: calc(100% - 93px);
  max-height: calc(100% - 93px);
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
}
@media screen and (max-width: 735px) {
  #frame .content .messages {
    max-height: calc(100% - 105px);
  }
}
#frame .content .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
#frame .content .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
#frame .content .messages ul li {
  display: inline-block;
  clear: both;
  /* float: left; */
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  font-size: 0.9em;
}
#frame .content .messages ul li:nth-last-child(1) {
  margin-bottom: 20px;
}
#frame .content .messages ul li.sent .chat-logo {
  margin: 6px 8px 0 0;
  background-color: #4b4746;
  height: 27px;
  width: 27px;
  color: #c5964f;
  font-weight: bolder;
  text-align: center;
  padding: 5px;
  border-radius: 50%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode',
      'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      text-transform: uppercase;
}
#frame .content .messages ul li.sent .chat-logo {
  margin: 6px 8px 0 0;
  background-color: #4b4746;
}
#frame .content .messages ul li.sent p {
  background: #7c766f;
  color: #f5f5f5;
}
#frame .content .messages ul li.replies .chat-logo {
  float: right;
  margin: 6px 0 0 8px;
  color: #4b4746;
  height: 27px;
  width: 27px;
  background-color: #c5964f;
  font-weight: bolder;
  text-align: center;
  padding: 5px;
  border-radius: 50%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode',
      'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
#frame .content .messages ul li.replies p {
  background: #f5f5f5;
  float: right;
}
#frame .content .messages ul li img {
  width: 22px;
  border-radius: 50%;
  float: left;
}
#frame .content .messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 205px;
  line-height: 130%;
}
@media screen and (min-width: 100px) {
  #frame .content .messages ul li p {
    max-width: 300px;
  }
}
#frame .content .message-input {
  position: absolute;
  bottom: 0;
  width: 109%;
  z-index: 99;
}
#frame .content .message-input .wrap {
  position: relative;
}
#frame .content .message-input .wrap input {
  float: left;
  border: none;
  width: calc(100% - 90px);
  padding: 11px 32px 10px 8px;
  color: #272627;
}
@media screen and (min-width: 1125px) {
  #frame .content .message-input .wrap input {
    width: calc(100% - 140px);
  }
}

#frame .content .message-input .wrap input:focus {
  outline: none;
}
#frame .content .message-input .wrap .attachment {
  position: absolute;
  right: 60px;
  z-index: 4;
  margin-top: 10px;
  font-size: 1.1em;
  color: #7c766f;
  opacity: .5;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap .attachment {
    margin-top: 17px;
    right: 65px;
  }
}
#frame .content .message-input .wrap .attachment:hover {
  opacity: 1;
}
#frame .content .message-input .wrap button {
  float: right;
  border: none;
  width: 80px;
  padding: 10px 0;
  cursor: pointer;
  background: #272627;
  color: #f5f5f5;
  position: revert;
  margin-right: 10px;
}
@media screen and (min-width: 1125px) {
  #frame .content .message-input .wrap button {
    margin-right: 20px;
  }
}

#frame .content .message-input .wrap button:hover {
  background: #7c766f;
}
#frame .content .message-input .wrap button:focus {
  outline: none;
}

.notification {
  float: right;
  padding-right: 2px;
}

</style>
